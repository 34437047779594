import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import "../styles/Work.less";

import FooterContent from "../containers/Footer/FooterContent";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class ExpenseApp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>Finances &amp; Time Reporting App - Eggnita</title>
            <link rel="canonical" href="https://eggnita.com/work/expenses-time-management" />
            <meta name="description" content="One of Sweden's largest digital accounting companies, contracted us in 2015 to design and implement their mobile app, so company management and finance executives could have complete control of their process." />
            <meta name="keywords" content="finance, time, reporting, app, digital, accounting, sweden, scandinavia, design, ui, ux, front end" />
            <meta name="robots" content="index,follow" />
            
            <meta name="og:title" content="Finances &amp; Time Reporting App - Eggnita" />
            <meta name="og:type" content="article" />
            <meta name="og:url" content="https://eggnita.com/work/expenses-time-management" />
            <meta name="og:image" content="https://eggnita.com/og-image.png" />
            <meta name="og:site_name" content="Eggnita" />
            <meta name="og:description" content="One of Sweden's largest digital accounting companies, contracted us in 2015 to design and implement their mobile app, so company management and finance executives could have complete control of their process." />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@eggnitastudio" />
            <meta name="twitter:title" content="Finances &amp; Time Reporting App - Eggnita" />
            <meta name="twitter:description" content="One of Sweden's largest digital accounting companies, contracted us in 2015 to design and implement their mobile app, so company management and finance executives could have complete control of their process." />  
            <meta name="twitter:creator" content="@eggnitastudio" />
            <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

            <script type="application/ld+json">{`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Work",
                "item": "https://eggnita.com/work"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Finances &amp; Time Reporting App",
                "item": "https://eggnita.com/work/expenses-time-management"
              }]
            }
            `}</script>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li className="selected">
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li className="selected">
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding project-page-details">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between top-align">
                  <div className="section-copy wide-content">
                    <nav className="breadcrumb">
                      <ol>
                        <li>
                          <Link to='/work'><ChevronLeft className="icon" /> Work</Link>
                        </li>
                        <li className="selected">
                          <strong>Finances &amp; Time Reporting App [NDA]</strong>
                        </li>
                      </ol>
                    </nav>
                    <h1>Finances &amp; Time Reporting App [NDA]<span className="primary-color">.</span></h1>
                    <h3 className="normal-weight">One of Sweden's largest digital accounting companies, contracted us in 2015 to design and implement their mobile app, so company management and finance executives could have complete control of their process.</h3>
                  </div>
                  <div className="section-side project-info">
                    <div className="info-item">
                      <strong>Client</strong>
                      <span>[NDA]</span>
                    </div>
                    <div className="info-item">
                      <strong>Year</strong>
                      <span>2015</span>
                    </div>
                    <div className="info-item">
                      <strong>Services</strong>
                      <span>UI/UX Design, Front-end Development</span>
                    </div>
                  </div>
              </div>
              <div className="spacer-64" />
              <div className="project-image">
                <img src={require('../assets/images/project-image-finance-time-report-01.jpg')} alt="Finances &amp; Time Reporting App [NDA - App Flow" className="media-item" />
              </div>
              <div className="project-navigation flexbox space-between">
                <Link to='/work/seo-site-checkup' className="nav-link previous flexbox">
                  <ChevronLeft className="icon" />
                  <div className="aligner">
                    <strong>Previous project</strong>
                    <div className="project-name">SEO Site Checkup</div>
                  </div>
                </Link>
                <Link to='/work/uefa-foundation' className="nav-link next flexbox">
                  <div className="aligner">
                    <strong>Next project</strong>
                    <div className="project-name">UEFA Foundation for Children</div>
                  </div>
                  <ChevronRight className="icon" />
                </Link>
              </div>
            </div>
          </section>
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("expenses-time-management"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseApp);
