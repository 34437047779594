import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { ArrowDownward } from '@material-ui/icons';

import "./MainHeroContent.less";

export default class MainHeroContent extends Component {
  render() {
    return (
        <section className="big-hero">
          <div className="content-wrapper">
            <h1>Eggnita's vision is a world where digital technologies can improve lives.</h1>
            <AnchorLink href="#after-hero" className="button-link has-icon">
              <ArrowDownward className="icon" /> 
            </AnchorLink>
          </div>
        </section>
    );
  }
}
