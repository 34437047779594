import fetch from "cross-fetch";
import { CONTENT_API_URL } from "../../config";
import { shouldFetchPage } from "../../selectors/contentSelector";

export const LOAD_PAGE_CONTENT = "LOAD_PAGE_CONTENT";
export const LOAD_PAGE_CONTENT_BLOCKS = "LOAD_PAGE_CONTENT_BLOCKS";
export const PAGE_CONTENT_LOADED = "PAGE_CONTENT_LOADED";
export const PAGE_CONTENT_BLOCKS_LOADED = "PAGE_CONENT_BLOCKS_LOADED";

export const loadPageContent = pageSlug => ({
  type: LOAD_PAGE_CONTENT,
  pageSlug: pageSlug
});

export const loadPageContentBlocks = pageSlug => ({
  type: LOAD_PAGE_CONTENT_BLOCKS,
  pageSlug: pageSlug
});

export const pageContentLoaded = (content, pageSlug) => ({
  type: PAGE_CONTENT_LOADED,
  content: content,
  pageSlug: pageSlug
});

export const contentBlocksLoaded = (contentBlocks, pageSlug) => ({
  type: PAGE_CONTENT_BLOCKS_LOADED,
  contentBlocks: contentBlocks,
  pageSlug: pageSlug
});

// Fetch content blocks for page
export function fetchContentBlocksForPage(pageSlug) {
  return function(dispatch, getState) {
    dispatch(loadPageContentBlocks(pageSlug));
    return fetch(`${CONTENT_API_URL}/contentblocks?page=${pageSlug}&_limit=300`)
      .then(
        response => response.json(),
        error => console.log("An error occurred.", error)
      )
      .then(response => {
        if (response.length) {
          dispatch(contentBlocksLoaded(response, pageSlug));
        } else {
          dispatch(contentBlocksLoaded({}, pageSlug));
        }
      });
  };
}

// Fetch content for a specific page and dispatch fecth for content blocks for page
export function fetchContentForPage(pageSlug, includeBlocks = true) {
  return function(dispatch, getState) {
    if (!shouldFetchPage(getState(), { pageSlug: pageSlug })) {
      return Promise.resolve();
    }
    dispatch(loadPageContent(pageSlug));
    return fetch(`${CONTENT_API_URL}/pages?slug=${pageSlug}`)
      .then(
        response => response.json(),
        error => console.log("An error occurred.", error)
      )
      .then(response => {
        if (response.length) {
          dispatch(pageContentLoaded(response[0], pageSlug));
          if (includeBlocks) {
            dispatch(fetchContentBlocksForPage(pageSlug));
          }
        } else {
          dispatch(pageContentLoaded({}, pageSlug));
        }
      });
  };
}
