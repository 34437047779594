import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import "../styles/Services.less";

import FooterContent from "../containers/Footer/FooterContent";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class PrivacyTOS extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper privacy-tos">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>Privacy &amp; Terms of Use - Eggnita</title>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding service-detail">
            <div className="content-wrapper content-copy">
            <div>
<h1>Privacy Policy of <strong>eggnita.com</strong></h1>
<p>This Website collects some Personal Data from its Users.</p>
</div>
<div>
<h2>Owner and Data Controller</h2>
<p>Eggnita Studio SRL, str. Salc&acirc;mului, nr. 30, Cluj-Napoca, Cluj, Romania</p>
<p><strong>Owner contact email:</strong> office@eggnita.com</p>
</div>
<div>
<h2>Types of Data collected</h2>
<p>Among the types of Personal Data that this Website collects, by itself or through third parties, there are: Cookies; Usage Data.</p>
<p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br/>Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Website.<br/>Unless specified otherwise, all Data requested by this Website is mandatory and failure to provide this Data may make it impossible for this Website to provide its services. In cases where this Website specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.<br/>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br/>Any use of Cookies &ndash; or of other tracking tools &ndash; by this Website or by the owners of third-party services used by this Website serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
<p>Users are responsible for any third-party Personal Data obtained, published or shared through this Website and confirm that they have the third party's consent to provide the Data to the Owner.</p>
</div>
<div>
<h2>Mode and place of processing the Data</h2>
<h3>Methods of processing</h3>
<p>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.<br/>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Website (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</p>
<h3>Legal basis of processing</h3>
<p>The Owner may process Personal Data relating to Users if one of the following applies:</p>
<ul>
<li>Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (&ldquo;opt-out&rdquo;), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</li>
<li>provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</li>
<li>processing is necessary for compliance with a legal obligation to which the Owner is subject;</li>
<li>processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</li>
<li>processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</li>
</ul>
<p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
<h3>Place</h3>
<p>The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.<br/><br/> Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</p>
<p>Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.<br/><br/> If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.</p>
<h3>Retention time</h3>
<p>Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</p>
<p>Therefore:</p>
<ul>
<li>Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</li>
<li>Personal Data collected for the purposes of the Owner&rsquo;s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.</li>
</ul>
<p>The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.<br/><br/> Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</p>
</div>
<div>
<h2>The purposes of processing</h2>
<p>The Data concerning the User is collected to allow the Owner to provide its Services, as well as for the following purposes: Analytics, Hosting and backend infrastructure and Tag Management.</p>
<p>Users can find further detailed information about such purposes of processing and about the specific Personal Data used for each purpose in the respective sections of this document.</p>
</div>
<div>
<h2>Detailed information on the processing of Personal Data</h2>
<p>Personal Data is collected for the following purposes and using the following services:</p>
<ul>
<li>
<div>
<h3>Analytics</h3>
<div>
<p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>
<h4>Google Analytics (Google LLC)</h4>
<div>
<p>Google Analytics is a web analysis service provided by Google LLC (&ldquo;Google&rdquo;). Google utilizes the Data collected to track and examine the use of this Website, to prepare reports on its activities and share them with other Google services.<br/> Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
</div>
<p>Personal Data collected: Cookies; Usage Data.</p>
<p>Place of processing: United States &ndash; <a href="https://policies.google.com/privacy">Privacy Policy</a> &ndash; <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</a>. Privacy Shield participant.</p>
</div>
<h4>HubSpot Analytics (HubSpot, Inc.)</h4>
<div>
<p>HubSpot Analytics is an analytics service provided by HubSpot, Inc.</p>
</div>
<p>
Personal Data collected:
Cookies; Usage Data.
</p>
<p>
Place of processing:
United States
&ndash;
<a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> &ndash; <a href="mailto:privacy@hubspot.com">Opt Out</a> .
</p>
</div>
</li>
<li>
<div>
<h3>Hosting and backend infrastructure</h3>
<div>
<p>This type of service has the purpose of hosting Data and files that enable this Website to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of this Website.</p>
<p>Some services among those listed below, if any, may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.</p>
<h4>Amazon Web Services (AWS) (Amazon Web Services, Inc.)</h4>
<div>
<p>Amazon Web Services (AWS) is a hosting and backend service provided by Amazon Web Services, Inc.</p>
</div>
<p>Personal Data collected: various types of Data as specified in the privacy policy of the service.</p>
<p>Place of processing: United States &ndash; <a href="https://aws.amazon.com/compliance/data-privacy-faq/">Privacy Policy</a>. Privacy Shield participant.</p>
</div>
</div>
</li>
<li>
<div>
<h3>Tag Management</h3>
<div>
<p>This type of service helps the Owner to manage the tags or scripts needed on this Website in a centralized fashion.<br/> This results in the Users' Data flowing through these services, potentially resulting in the retention of this Data.</p>
<h4>Google Tag Manager (Google LLC)</h4>
<div>
<p>Google Tag Manager is a tag management service provided by Google LLC.</p>
</div>
<p>Personal Data collected: Cookies; Usage Data.</p>
<p>Place of processing: United States &ndash; <a href="https://policies.google.com/privacy">Privacy Policy</a>. Privacy Shield participant.</p>
</div>
</div>
</li>
<li>
<h3>User database management</h3>
<div>

<p>This type of service allows the Owner to build user profiles by starting from an email address, a personal name, or other information that the User provides to this Website, as well as to track User activities through analytics features. This Personal Data may also be matched with publicly available information about the User (such as social networks' profiles) and used to build private profiles that the Owner can display and use for improving this Website.<br/>
Some of these services may also enable the sending of timed messages to the User, such as emails based on specific actions performed on this Website.</p>


<h4>HubSpot CRM (HubSpot, Inc.)</h4>

<div>
<p>HubSpot CRM is a User database management service provided by HubSpot, Inc.</p>
</div>


<p>
Personal Data collected:
email address; phone number; various types of Data as specified in the privacy policy of the service.
</p>


<p>
Place of processing:
United States
&ndash;
<a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
Privacy Shield participant.
</p>
</div>
</li>
</ul>
</div>
<div>
  
<h2>The rights of Users</h2>
<p>Users may exercise certain rights regarding their Data processed by the Owner.</p>
<p>In particular, Users have the right to do the following:</p>
<ul>
<li><strong>Withdraw their consent at any time.</strong> Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.</li>
<li><strong>Object to processing of their Data.</strong> Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.</li>
<li><strong>Access their Data.</strong> Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.</li>
<li><strong>Verify and seek rectification.</strong> Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.</li>
<li><strong>Restrict the processing of their Data.</strong> Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</li>
<li><strong>Have their Personal Data deleted or otherwise removed.</strong> Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</li>
<li><strong>Receive their Data and have it transferred to another controller.</strong> Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.</li>
<li><strong>Lodge a complaint.</strong> Users have the right to bring a claim before their competent data protection authority.</li>
</ul>
<h3>Details about the right to object to processing</h3>
<p>Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.</p>
<p>Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.</p>
<h3>How to exercise these rights</h3>
<p>Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.</p>
</div>
<div>
<h2>Additional information about Data collection and processing</h2>
<h3>Legal action</h3>
<p>The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Website or the related Services.<br/>The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.</p>
<h3>Additional information about User's Personal Data</h3>
<p>In addition to the information contained in this privacy policy, this Website may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.</p>
<h3>System logs and maintenance</h3>
<p>For operation and maintenance purposes, this Website and any third-party services may collect files that record interaction with this Website (System logs) use other Personal Data (such as the IP Address) for this purpose.</p>
<h3>Information not contained in this policy</h3>
<p>More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.</p>
<h3>How &ldquo;Do Not Track&rdquo; requests are handled</h3>
<p>This Website does not support &ldquo;Do Not Track&rdquo; requests.<br/>To determine whether any of the third-party services it uses honor the &ldquo;Do Not Track&rdquo; requests, please read their privacy policies.</p>
<h3>Changes to this privacy policy</h3>
<p>The Owner reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page and possibly within this Website and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. <br/><br/> Should the changes affect processing activities performed on the basis of the User&rsquo;s consent, the Owner shall collect new consent from the User, where required.</p>
</div>
<div>
<div>
<h3>Definitions and legal references</h3>
<div>
<h4>Personal Data (or Data)</h4>
<p>Any information that directly, indirectly, or in connection with other information &mdash; including a personal identification number &mdash; allows for the identification or identifiability of a natural person.</p>
<h4>Usage Data</h4>
<p>Information collected automatically through this Website (or third-party services employed in this Website), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>
<h4>User</h4>
<p>The individual using this Website who, unless otherwise specified, coincides with the Data Subject.</p>
<h4>Data Subject</h4>
<p>The natural person to whom the Personal Data refers.</p>
<h4>Data Processor (or Data Supervisor)</h4>
<p>The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.</p>
<h4>Data Controller (or Owner)</h4>
<p>The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Website. The Data Controller, unless otherwise specified, is the Owner of this Website.</p>
<h4>This Website (or this Application)</h4>
<p>The means by which the Personal Data of the User is collected and processed.</p>
<h4>Service</h4>
<p>The service provided by this Website as described in the relative terms (if available) and on this site/application.</p>
<h4>European Union (or EU)</h4>
<p>Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.</p>
<h4>Cookies</h4>
<p>Small sets of data stored in the User's device.</p>
<hr/>
<h4>Legal information</h4>
<p>This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</p>
<p>This privacy policy relates solely to this Website, if not stated otherwise within this document.</p>
</div>
</div>
</div>


<div>
<div>
<div>
<div>
<div>
<h1>Terms and Conditions of <strong>eggnita.com/</strong></h1>
<p>These Terms govern</p>
<ul>
<li>the use of this Website, and,</li>
<li>any other related Agreement or legal relationship with the Owner</li>
</ul>
<p>in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</p>
<br/>
<p>The User must read this document carefully.</p>
</div>
<p>This Website is provided by:</p>
<p>Eggnita Studio SRL, str. Salc&acirc;mului, nr. 30, Cluj-Napoca, Cluj, Romania</p>
<p><strong>Owner contact email:</strong> office@eggnita.com</p>
<h2>What the User should know at a glance</h2>
<ul>
<li>Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.</li>
</ul>
<hr/>
<h2>TERMS OF USE</h2>
<p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Website.</p>
<p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>
<p>By using this Website, Users confirm to meet the following requirements:</p>
<ul>
<li>There are no restrictions for Users in terms of being Consumers or Business Users;</li>
</ul>
<h3>Content on this Website</h3>
<p>Unless where otherwise specified or clearly recognizable, all content available on this Website is owned or provided by the Owner or its licensors.</p>
<p>The Owner undertakes its utmost effort to ensure that the content provided on this Website infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.<br/> In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
<h4>Rights regarding content on this Website - All rights reserved</h4>
<p>The Owner holds and reserves all intellectual property rights for any such content.</p>
<p>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</p>
<p>In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Website, nor allow any third party to do so through the User or their device, even without the User's knowledge.</p>
<p>Where explicitly stated on this Website, the User may download, copy and/or share some content available through this Website for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.</p>
<p>Any applicable statutory limitation or exception to copyright shall stay unaffected.</p>
<h3>Access to external resources</h3>
<p>Through this Website Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>
<p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties&rsquo; terms and conditions or, in the absence of those, applicable statutory law.</p>
<h3>Acceptable use</h3>
<p>This Website and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>
<p>Users are solely responsible for making sure that their use of this Website and/or the Service violates no applicable law, regulations or third-party rights.</p>
<p>Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Website or the Service, terminating contracts, reporting any misconduct performed through this Website or the Service to the competent authorities &ndash; such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:</p>
<ul>
<li>violate laws, regulations and/or these Terms;</li>
<li>infringe any third-party rights;</li>
<li>considerably impair the Owner&rsquo;s legitimate interests;</li>
<li>offend the Owner or any third party.</li>
</ul>
<h2>Liability and indemnification</h2>
<h3>Australian Users</h3>
<h4>Limitation of liability</h4>
<p>Nothing in these Terms excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act 2010 (Cth) or any similar State and Territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms of Use, is limited, at the Owner&rsquo;s sole discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.</p>
<h3>US Users</h3>
<h4>Disclaimer of Warranties</h4>
<p><strong>This Website is provided strictly on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. Use of the Service is at Users&rsquo; own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties &mdash; whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by user from owner or through the Service will create any warranty not expressly stated herein.</strong></p>
<p><strong>Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users&rsquo; requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at users own risk and users shall be solely responsible for any damage to Users&rsquo; computer system or mobile device or loss of data that results from such download or Users&rsquo; use of the Service.</strong></p>
<p><strong>The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.</strong></p>
<p><strong>The Service may become inaccessible or it may not function properly with Users&rsquo; web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.</strong></p>
<p><strong>Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.</strong></p>
<h4>Limitations of liability</h4>
<p><strong>To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for</strong></p>
<ul>
<li><strong>any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and</strong></li>
<li><strong>any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;</strong></li>
<li><strong>any errors, mistakes, or inaccuracies of content;</strong></li>
<li><strong>personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;</strong></li>
<li><strong>any unauthorized access to or use of the Owner&rsquo;s secure servers and/or any and all personal information stored therein;</strong></li>
<li><strong>any interruption or cessation of transmission to or from the Service;</strong></li>
<li><strong>any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;</strong></li>
<li><strong>any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or</strong></li>
<li><strong>the defamatory, offensive, or illegal conduct of any User or third party. In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.</strong></li>
</ul>
<p><strong>This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if company has been advised of the possibility of such damage.</strong></p>
<p><strong>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User. The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.</strong></p>
<h4>Indemnification</h4>
<p><strong>The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from</strong></p>
<ul>
<li><strong>User&rsquo;s use of and access to the Service, including any data or content transmitted or received by User;</strong></li>
<li><strong>User&rsquo;s violation of these terms, including, but not limited to, User&rsquo;s breach of any of the representations and warranties set forth in these terms;</strong></li>
<li><strong>User&rsquo;s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;</strong></li>
<li><strong>User&rsquo;s violation of any statutory law, rule, or regulation;</strong></li>
<li><strong>any content that is submitted from User&rsquo;s account, including third party access with User&rsquo;s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;</strong></li>
<li><strong>User&rsquo;s willful misconduct; or</strong></li>
<li><strong>statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.</strong></li>
</ul>
<h2>Common provisions</h2>
<h3>No Waiver</h3>
<p>The Owner&rsquo;s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
<h3>Service interruption</h3>
<p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>
<p>Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.</p>
<p>Additionally, the Service might not be available due to reasons outside the Owner&rsquo;s reasonable control, such as &ldquo;force majeure&rdquo; (eg. labor actions, infrastructural breakdowns or blackouts etc).</p>
<h3>Service reselling</h3>
<p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Website and of its Service without the Owner&rsquo;s express prior written permission, granted either directly or through a legitimate reselling program.</p>
<h3>Privacy policy</h3>
<p>To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Website.</p>
<h3>Intellectual property rights</h3>
<p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Website are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>
<p>All trademarks &mdash; nominal or figurative &mdash; and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Website are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
<h3>Changes to these Terms</h3>
<p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>
<p>Such changes will only affect the relationship with the User for the future.</p>
<p>The continued use of the Service will signify the User&rsquo;s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement.</p>
<p>The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.</p>
<p>If required by applicable law, the Owner will specify the date by which the modified Terms will enter into force.</p>
<h3>Assignment of contract</h3>
<p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User&rsquo;s legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly.</p>
<p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
<h3>Contacts</h3>
<p>All communications relating to the use of this Website must be sent using the contact information stated in this document.</p>
<h3>Severability</h3>
<p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
<h4>US Users</h4>
<p>Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent. These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter. These Terms will be enforced to the fullest extent permitted by law.</p>
<h4>EU Users</h4>
<p>Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.<br/> In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.</p>
<p>Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.</p>
<h3>Governing law</h3>
<p>These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.</p>
<h4>Exception for European Consumers</h4>
<p>However, regardless of the above, if the User qualifies as a European Consumer and has their habitual residence in a country where the law provides for a higher consumer protection standard, such higher standards shall prevail.</p>
<h3>Venue of jurisdiction</h3>
<p>The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.</p>
<h4>Exception for European Consumers</h4>
<p>The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in Switzerland, Norway or Iceland.</p>
<div>
<div>
<h3>Definitions and legal references</h3>
<div>
<h4>This Website (or this Application)</h4>
<p>The property that enables the provision of the Service.</p>
<h4>Agreement</h4>
<p>Any legally binding or contractual relationship between the Owner and the User, governed by these Terms.</p>
<h4>Business User</h4>
<p>Any User that does not qualify as a Consumer.</p>
<h4>European (or Europe)</h4>
<p>Applies where a User is physically present or has their registered offices within the EU, regardless of nationality.</p>
<h4>Owner (or We)</h4>
<p>Indicates the natural person(s) or legal entity that provides this Website and/or the Service to Users.</p>
<h4>Service</h4>
<p>The service provided by this Website as described in these Terms and on this Website.</p>
<h4>Terms</h4>
<p>All provisions applicable to the use of this Website and/or the Service as described in this document, including any other related documents or agreements, and as updated from time to time.</p>
<h4>User (or You)</h4>
<p>Indicates any natural person or legal entity using this Website.</p>
<h4>Consumer</h4>
<p>Any User qualifying as a natural person who accesses goods or services for personal use, or more generally, acts for purposes outside their trade, business, craft or profession.</p>
</div>
</div>
</div>
<div>
<p>Latest update: October 10, 2019</p>
</div>
</div>
</div>
</div>
</div>



            
            
            </div>
          </section>
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("privacy-terms-of-use"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyTOS);
