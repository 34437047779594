import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import { ChevronRight } from '@material-ui/icons';
import "../styles/Careers.less";

import FooterContent from "../containers/Footer/FooterContent";
import OurProcess from "../containers/OurProcess/OurProcess";
import TechStack from "../containers/TechStack/TechStack";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class Careers extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>Careers — Eggnita</title>
            <link rel="canonical" href="https://eggnita.com/careers" />
            <meta name="description" content="We're always looking for new talents to join our team. If you would like to become an Eggniter but you can't find your field listed below, please drop us an email." />
            <meta name="keywords" content="careers, eggnita, javascrip, html5, css3, git, bitbucket, job, position, developer, full time, senior, cluj, iasi" />
            <meta name="robots" content="index,follow" />
            
            <meta name="og:title" content="Careers — Eggnita" />
            <meta name="og:type" content="article" />
            <meta name="og:url" content="https://eggnita.com/careers" />
            <meta name="og:image" content="https://eggnita.com/og-image.png" />
            <meta name="og:site_name" content="Eggnita" />
            <meta name="og:description" content="We're always looking for new talents to join our team. If you would like to become an Eggniter but you can't find your field listed below, please drop us an email." />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@eggnitastudio" />
            <meta name="twitter:title" content="Careers — Eggnita" />
            <meta name="twitter:description" content="We're always looking for new talents to join our team. If you would like to become an Eggniter but you can't find your field listed below, please drop us an email." />  
            <meta name="twitter:creator" content="@eggnitastudio" />
            <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Eggnita",
                "alternateName": "Eggnita Studio SRL",
                "url": "https://eggnita.com",
                "logo": "https://eggnita.com/logo.png",
                "email": "mailto:office@eggnita.com",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Cluj-Napoca",
                  "addressRegion": "Cluj",
                  "postalCode": "400377",
                  "streetAddress": "Salcamului 30"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+40-741-153-949",
                  "contactType": "customer service",
                  "contactOption": "TollFree",
                  "areaServed": ["150","419","US","GB","CA"],
                  "availableLanguage": ["en","Romanian"]
                },
                "sameAs": [
                  "https://www.facebook.com/eggnita",
                  "https://twitter.com/eggnitastudio",
                  "https://www.linkedin.com/company/eggnita",
                  "https://github.com/eggnita"
                ]
              }
            `}</script>

            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org/",
                    "@type": "JobPosting",
                    "title": "JavaScript Developer",
                    "description": "<h6><strong>Basic Skills</strong></h6>
                                        <ul>
                                            <li>Fluent English with good writing and conversational skills</li>
                                            <li>3+ years of JavaScript development experience</li>
                                            <li>Strong HTML5 and CSS3 knowledge</li>
                                            <li>Strong knowledge of version control systems (Git, Bitbucket)</li>
                                            <li>Problem solving skills</li>
                                            <li>Learning abilities</li>
                                        </ul>
                                        <h6><strong>Desired Skills</strong></h6>
                                        <ul>
                                            <li>Work as a part of a startup and implement client side logic backed up by an existent REST backend solution</li>
                                            <li>Build test driven and reusable polymer js components according to the specifications</li>
                                            <li>Attend periodic meetings</li>
                                            <li>Have fun</li>
                                        </ul>
                                        <h6><strong>Benefits</strong></h6>
                                        <ul>
                                            <li>A salary package that will reflect your experience, skills and effort</li>
                                            <li>Onboarding training on the application stack</li>
                                            <li>Flexible working schedule</li>
                                            <li>Working with the latest technologies</li>
                                            <li>Daily lunch at the company office or at nearby restaurants</li>
                                        </ul>",
                    "hiringOrganization" : {
                    "@type": "Organization",
                    "name": "Eggnita",
                    "sameAs": "https://eggnita.com"
                    },
                    "industry": "IT&C",
                    "employmentType": "FULL_TIME",
                    "workHours": "8",
                    "datePosted": "2019-10-04",
                    "validThrough": "2020-05-01",
                    "baseSalary": {
                      "@type": "MonetaryAmount",
                      "currency": "EUR",
                      "value": {
                        "@type": "QuantitativeValue",
                        "value": 1500.00,
                        "unitText": "MONTH"
                      }
                    },
                    "jobLocation": {
                    "@type": "Place",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Salcamului 30",
                        "addressLocality": "Cluj-Napoca",
                        "addressRegion": "CJ",
                        "postalCode": "400377",
                        "addressCountry": "RO"
                    }
                    },
                    "responsibilities": "Work as a part of a startup and implement client side logic backed up by an existent REST backend solution",
                    "experienceRequirements": "3+ years of JavaScript development experience"
                }
            `}</script>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li className="selected">
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/work'>Work</Link>
                </li>
                <li className="selected">
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding career-page-hero">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between">
                  <div className="section-copy wide-content">
                    <h1>Careers<span className="primary-color">.</span></h1>
                    <h3 className="normal-weight">We're always looking for new talents to join our team. If you would like to become an Eggniter but you can't find your field listed below, please <a href="mailto:office@eggnita.com" target="_blank" rel="noopener noreferrer" className="default-inline-link">drop us an e-mail</a>.</h3>
                  </div>
              </div>
              <div className="career-item">
                  <div className="career-description">
                      <h2>JavaScript Developer</h2>
                      <h6>Basic Skills</h6>
                      <ul>
                          <li>​Fluent English with good writing and conversational skills</li>
                          <li>3+ years of JavaScript development experience</li>
                          <li>Strong HTML5 and CSS3 knowledge</li>
                          <li>Strong knowledge of version control systems (Git, Bitbucket)</li>
                          <li>Problem solving skills</li>
                          <li>Learning abilities</li>
                      </ul>
                      <h6>Desired Skills</h6>
                      <ul>
                          <li>Work as a part of a startup and implement client side logic backed up by an existent REST backend solution</li>
                          <li>Build test driven and reusable polymer js components according to the specifications</li>
                          <li>Attend periodic meetings</li>
                          <li>Have fun</li>
                      </ul>
                      <h6>Benefits</h6>
                      <ul>
                          <li>A salary package that will reflect your experience, skills and effort</li>
                          <li>Onboarding training on the application stack</li>
                          <li>Flexible working schedule</li>
                          <li>Working with the latest technologies</li>
                          <li>Daily lunch at the company office or at nearby restaurants</li>
                      </ul>
                      <a href="mailto:office@eggnita.com?Subject=JavaScript Developer: job application" target="_blank" rel="noopener noreferrer" className="main-link"><ChevronRight className="icon" /> Apply to this Job</a>
                  </div>

                  <div className="career-meta">
                    <div className="meta-item">
                        <strong>Location:</strong>
                        <span>Cluj-Napoca / Iași</span>
                    </div>
                    <div className="meta-item">
                        <strong>Level:</strong>
                        <span>Mid-level / Senior</span>
                    </div>
                    <div className="meta-item">
                        <strong>Job type:</strong>
                        <span>Full time</span>
                    </div>
                    <div className="meta-item">
                        <strong>Language:</strong>
                        <span>English</span>
                    </div>
                  </div>
              </div>
            </div>
          </section>
        </main>
        <TechStack />
        <div className="white-background"><OurProcess /></div>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("careers"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Careers);
