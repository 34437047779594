import React, { Component } from "react";
import { Link } from "@reach/router";
import CustomIcon from "../../components/CustomIcon";

import "./FooterContent.less";
export default class FooterContent extends Component {
  render() {
    return (
        <footer className="app-footer small-padding">
          <div className="content-wrapper">
            <h1 className="light-weight">We're here to make great things.</h1>
            <div className="general-info">
              <div className="item">
                <h5 className="normal-weight">Let's get in touch</h5>
                <a href="mailto:office@eggnita.com" target="_blank" rel="noopener noreferrer">office@eggnita.com</a>
              </div>
              <div className="item">
                <h5 className="normal-weight">Still not convinced?</h5>
                <Link to='/work'>Check our work samples</Link>
              </div>
            </div>
            <div className="social-copy flexbox space-between">
              <div className="social-links">
                <a href="https://www.facebook.com/eggnita" target="_blank" rel="noopener noreferrer">
                  <CustomIcon name="facebook" className="icon" />
                </a>
                <a href="https://twitter.com/eggnitastudio" target="_blank" rel="noopener noreferrer">
                  <CustomIcon name="twitter" className="icon" />
                </a>
                <a href="https://www.linkedin.com/company/eggnita" target="_blank" rel="noopener noreferrer">
                  <CustomIcon name="linked-in" className="icon" />
                </a>
                <a href="https://dribbble.com/eggnita" target="_blank" rel="noopener noreferrer">
                  <CustomIcon name="dribbble" className="icon" />
                </a>
                <a href="https://www.behance.net/eggnita" target="_blank" rel="noopener noreferrer">
                  <CustomIcon name="behance" className="icon" />
                </a>
                <a href="https://medium.com/@eggnita" target="_blank" rel="noopener noreferrer">
                  <CustomIcon name="medium" className="icon" />
                </a>
              </div>
              <div className="copyright">
                <Link to='/privacy-terms-of-use'>Privacy and TOS</Link>
                <span>&copy; 2019 Eggnita</span>
              </div>
            </div>
          </div>
        </footer>
    );
  }
}
