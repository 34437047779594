import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";
import loggerMiddleware from "./middleware/logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadTranslations, setLocale, syncTranslationWithStore } from "react-redux-i18n";
import translations from "./locale";
import { hotjar } from "react-hotjar";
import { ENV } from "./config";

if (ENV === "prod") {
  hotjar.initialize(1539938, 6);
}
const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composedEnhancers = compose(middlewareEnhancer);
const enhancers = composeWithDevTools(composedEnhancers);
const store = createStore(rootReducer, enhancers);
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("en"));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
