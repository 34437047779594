import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import CustomIcon from "../components/CustomIcon";
import "../styles/Services.less";

import FooterContent from "../containers/Footer/FooterContent";
import WorkSamplesContent from "../containers/WorkSamples/WorkSamplesContent";
import TechStack from "../containers/TechStack/TechStack";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class SocialMedia extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>Social Media Services - Eggnita</title>
            <link rel="canonical" href="https://eggnita.com/services/social-media" />
            <meta name="description" content="To build brand awareness and engage people towards your cause you need social media. We help our clients keep up with the competition and stand out among them." />
            <meta name="keywords" content="social, media, service, eggnita, brand, awarness, engage, marketing, competition" />
            <meta name="robots" content="index,follow" />
            
            <meta name="og:title" content="Social Media Services - Eggnita" />
            <meta name="og:type" content="article" />
            <meta name="og:url" content="https://eggnita.com/services/social-media" />
            <meta name="og:image" content="https://eggnita.com/og-image.png" />
            <meta name="og:site_name" content="Eggnita" />
            <meta name="og:description" content="To build brand awareness and engage people towards your cause you need social media. We help our clients keep up with the competition and stand out among them." />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@eggnitastudio" />
            <meta name="twitter:title" content="Social Media Services - Eggnita" />
            <meta name="twitter:description" content="To build brand awareness and engage people towards your cause you need social media. We help our clients keep up with the competition and stand out among them." />  
            <meta name="twitter:creator" content="@eggnitastudio" />
            <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

            <script type="application/ld+json">{`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Services",
                "item": "https://eggnita.com/services"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Social Media",
                "item": "https://eggnita.com/services/social-media"
              }]
            }
            `}</script>

            <script type="application/ld+json">{`
            {
              "@context": "http://schema.org/",
              "@type": "Service",
              "serviceType": "Social Media"
            }
            `}</script>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li className="selected">
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li className="selected">
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding service-detail">
            <div className="content-wrapper">
                  <div className="section-content flexbox space-between">
                      <div className="section-copy">
                        <nav className="breadcrumb">
                          <ol>
                            <li>
                              <Link to='/services'><ChevronLeft className="icon" /> Services</Link>
                            </li>
                            <li className="selected">
                              <strong>Social Media</strong>
                            </li>
                          </ol>
                        </nav>
                        <h1>Social Media<span className="primary-color">.</span></h1>
                        <h3 className="normal-weight">No matter the market or the complexity of the project, our services include all the solutions to develop and to bring to life products that users will love.</h3>
                        <p className="larger-paragraph">To build brand awareness and engage people towards your cause you need social media. We help our clients keep up with the competition and stand out among them. We create social media strategies to expand your marketing efforts and build brand awareness.</p>
                        <Link to='/contact' className="main-link"><ChevronRight className="icon" /> Get in touch</Link>
                      </div>
                      <div className="section-side">
                        <div className="service-image">
                          <div className="section-icon">
                            <CustomIcon name="social-media" className="icon" />
                          </div>
                          <img src={require('../assets/images/image-social-media.png')} alt="Social Media" className="media-item" />
                        </div>
                      </div>
                  </div>
            </div>
          </section>
          <TechStack />
          <div className="white-background"><WorkSamplesContent /></div>
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("social-media"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialMedia);
