import React, { Component } from "react";

import "./OurProcess.less";

export default class OurProcess extends Component {
  render() {
    return (
          <section className="main-section default-padding services-page-process gray-background">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between">
                  <div className="section-copy wide-content">
                    <h1>Our Process<span className="primary-color">.</span></h1>
                    <h3 className="normal-weight">Over time, we set up a strategic, process-oriented method, to create successful software, build applications and websites, execute marketing plans and give all the online support to our clients.</h3>
                  </div>
              </div>
              <div className="process-listing flexbox space-between top-align">
                <div className="process-item">
                  <div className="process-meta flexbox">
                    <div className="process-number">1</div>
                    <div className="process-name">Information Gathering</div>
                  </div>
                  <div className="process-description">
                    Gathering information to see what the business goals are and how the web can help to reach those professional dreams.
                  </div>
                </div>
                <div className="process-item">
                  <div className="process-meta flexbox">
                    <div className="process-number">2</div>
                    <div className="process-name">Validation</div>
                  </div>
                  <div className="process-description">
                    A feasibility study will analyze the market need and validate the main idea of your application or website.
                  </div>
                </div>
                <div className="process-item">
                  <div className="process-meta flexbox">
                    <div className="process-number">3</div>
                    <div className="process-name">The Prototype</div>
                  </div>
                  <div className="process-description">
                    Based on the information we have already gathered, it’s time to determine how the product will look and feel. This is the step when we create the prototype.
                  </div>
                </div>
                <div className="process-item">
                  <div className="process-meta flexbox">
                    <div className="process-number">4</div>
                    <div className="process-name">Development</div>
                  </div>
                  <div className="process-description">
                    The moment where our team of designers and developers will take all the graphic elements from the prototype and use them to create a functional software product.
                  </div>
                </div>
                <div className="process-item">
                  <div className="process-meta flexbox">
                    <div className="process-number">5</div>
                    <div className="process-name">Testing</div>
                  </div>
                  <div className="process-description">
                    The moment when we attend the last details and test the functionality of forms and scripts, the compatibility issues on desktop, mobile or tablet and validate the written code.
                  </div>
                </div>
                <div className="process-item">
                  <div className="process-meta flexbox">
                    <div className="process-number">6</div>
                    <div className="process-name">Maintenance</div>
                  </div>
                  <div className="process-description">
                    We will take care to keep your website fast and secure, up to date and in good working order.
                  </div>
                </div>
              </div>
            </div>
          </section>
    );
  }
}
