import React, { Component } from "react";
import { Router } from "@reach/router";
import Home from "./pages/Home";
import About from "./pages/About";

import Services from "./pages/Services";
import WebApplications from "./pages/WebApplications";
import MobileApplications from "./pages/MobileApplications";
import WebServices from "./pages/WebServices";
import BackEndSolutions from "./pages/BackEndSolutions";
import UiUxDesign from "./pages/UiUxDesign";
import CloudArchitecture from "./pages/CloudArchitecture";
import SocialMedia from "./pages/SocialMedia";
import Copywriting from "./pages/Copywriting";
import SeoServices from "./pages/SeoServices";
import StartupConsultancy from "./pages/StartupConsultancy";

import Work from "./pages/Work";
import Moxxie from "./pages/Moxxie";
import Ihopa from "./pages/Ihopa";
import Ssc from "./pages/Ssc";
import ExpenseApp from "./pages/ExpenseApp";
import UefaFoundation from "./pages/UefaFoundation";
import BabySimulator from "./pages/BabySimulator";
import AirlineApp from "./pages/AirlineApp";
import HrTest from "./pages/HrTest";
import PrivacyTOS from "./pages/PrivacyTOS";
import NotFound from "./pages/NotFound";

import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import "normalize.css";
import "./styles/App.less";

class App extends Component {
  render() {
    return (
      <Router primary={false}>
        <ScrollToTop path="/">
          <Home path="/" />
          <About path="/about" />

          <Services path="/services" />
          <WebApplications path="/services/web-applications" />
          <MobileApplications path="/services/mobile-applications" />
          <WebServices path="/services/web-services" />
          <BackEndSolutions path="/services/back-end-solutions" />
          <UiUxDesign path="/services/ui-ux-design" />
          <CloudArchitecture path="/services/aws-cloud-architecture" />
          <SocialMedia path="/services/social-media" />
          <Copywriting path="/services/copywriting" />
          <SeoServices path="/services/seo-services" />
          <StartupConsultancy path="/services/startup-consultancy" />

          <Work path="/work" />
          <Moxxie path="/work/moxxie" />
          <Ihopa path="/work/ihopa" />
          <Ssc path="/work/seo-site-checkup" />
          <ExpenseApp path="/work/expenses-time-management" />
          <UefaFoundation path="/work/uefa-foundation" />
          <BabySimulator path="/work/gjensidige-baby-simulator" />
          <AirlineApp path="/work/airline-travel-app" />
          <HrTest path="/work/hr-candidate-testing" />

          <Careers path="/careers" />
          <Contact path="/contact" />
          <PrivacyTOS path="/privacy-terms-of-use" />
          <NotFound path="*" />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
