import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import "../styles/Work.less";

import FooterContent from "../containers/Footer/FooterContent";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class HrTest extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
        <section className="app-wrapper">
          <Helmet>
              <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
              <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'UA-149439507-1');
                `}
              </script>
              <title>HR Candidate Testing [NDA] - Eggnita</title>
              <link rel="canonical" href="https://eggnita.com/work/hr-candidate-testing" />
              <meta name="description" content="In 2016 we created the UI/UX flows for a B2B solution where potential job candidates could be tested and accepted in different recruiting stages by HR professionals." />
              <meta name="keywords" content="hr, candidate, test, b2b, sweden, scandinavia, job, recruitment, tool, scorecard, calendar, messaging, overview, ui, ux, design" />
              <meta name="robots" content="index,follow" />
              
              <meta name="og:title" content="HR Candidate Testing [NDA] - Eggnita" />
              <meta name="og:type" content="article" />
              <meta name="og:url" content="https://eggnita.com/work/hr-candidate-testing" />
              <meta name="og:image" content="https://eggnita.com/og-image.png" />
              <meta name="og:site_name" content="Eggnita" />
              <meta name="og:description" content="In 2016 we created the UI/UX flows for a B2B solution where potential job candidates could be tested and accepted in different recruiting stages by HR professionals." />
  
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:site" content="@eggnitastudio" />
              <meta name="twitter:title" content="HR Candidate Testing [NDA] - Eggnita" />
              <meta name="twitter:description" content="In 2016 we created the UI/UX flows for a B2B solution where potential job candidates could be tested and accepted in different recruiting stages by HR professionals." />  
              <meta name="twitter:creator" content="@eggnitastudio" />
              <meta name="twitter:image" content="https://eggnita.com/og-image.png" />
  
              <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Work",
                  "item": "https://eggnita.com/work"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "HR Candidate Testing [NDA]",
                  "item": "https://eggnita.com/work/hr-candidate-testing"
                }]
              }
              `}</script>
          </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li className="selected">
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
          <Headroom>
            <header className="app-header flexbox space-between">
              <Link to='/'>
                <div className="logo" />
              </Link>
              <nav>
                <ul className="desktop-navigation">
                  <li>
                    <Link to='/about'>About</Link>
                  </li>
                  <li>
                    <Link to='/services'>Services</Link>
                  </li>
                  <li className="selected">
                    <Link to='/work'>Work</Link>
                  </li>
                  <li>
                    <Link to='/careers'>Careers</Link>
                  </li>
                  <li>
                    <Link to='/contact'>Contact</Link>
                  </li>
                </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
              </nav>
            </header>
          </Headroom>
          <main className="app-content">
            <section className="main-section default-padding project-page-details">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between top-align">
                  <div className="section-copy wide-content">
                    <nav className="breadcrumb">
                      <ol>
                        <li>
                          <Link to='/work'><ChevronLeft className="icon" /> Work</Link>
                        </li>
                        <li className="selected">
                          <strong>HR Candidate Testing [NDA]</strong>
                        </li>
                      </ol>
                    </nav>
                    <h1>Candidate Testing [NDA]<span className="primary-color">.</span></h1>
                    <h3 className="normal-weight">In 2016 we created the UI/UX flows for a B2B solution where potential job candidates could be tested and accepted in different recruiting stages by HR professionals.</h3>
                  </div>
                  <div className="section-side project-info">
                    <div className="info-item">
                      <strong>Client</strong>
                      <span>[NDA]</span>
                    </div>
                    <div className="info-item">
                      <strong>Year</strong>
                      <span>2016</span>
                    </div>
                    <div className="info-item">
                      <strong>Services</strong>
                      <span>UI/UX Design</span>
                    </div>
                  </div>
              </div>
              <div className="spacer-64" />
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">01</span> Candidate Flows</div>
                <img src={require('../assets/images/project-hr-candidate-testing-01.jpg')} alt="HR Candidate Testing [NDA] - Candidate Flows" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">02</span> Recruitment Tool</div>
                <img src={require('../assets/images/project-hr-candidate-testing-02.jpg')} alt="HR Candidate Testing [NDA] - Recruitment Tool" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">03</span> Candidates List</div>
                <img src={require('../assets/images/project-hr-candidate-testing-03.jpg')} alt="HR Candidate Testing [NDA] - Candidates List" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">04</span> Candidate Overview</div>
                <img src={require('../assets/images/project-hr-candidate-testing-04.jpg')} alt="HR Candidate Testing [NDA] - Candidate Overview" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">05</span> Candidate Activity</div>
                <img src={require('../assets/images/project-hr-candidate-testing-05.jpg')} alt="HR Candidate Testing [NDA] - Candidate Activity" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">06</span> Candidate Scorecards</div>
                <img src={require('../assets/images/project-hr-candidate-testing-06.jpg')} alt="HR Candidate Testing [NDA] - Candidate Scorecards" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">07</span> HR Calendar Agenda</div>
                <img src={require('../assets/images/project-hr-candidate-testing-07.jpg')} alt="HR Candidate Testing [NDA] - HR Calendar Agenda" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">08</span> HR Multi-channel Messaging List</div>
                <img src={require('../assets/images/project-hr-candidate-testing-08.jpg')} alt="HR Candidate Testing [NDA] - HR Multi-channel Messaging List" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">09</span> HR Multi-channel Message Details</div>
                <img src={require('../assets/images/project-hr-candidate-testing-09.jpg')} alt="HR Candidate Testing [NDA] - HR Multi-channel Message Details" className="media-item" />
              </div>
              <div className="project-navigation flexbox space-between">
                <Link to='/work/airline-travel-app' className="nav-link previous flexbox">
                  <ChevronLeft className="icon" />
                  <div className="aligner">
                    <strong>Previous project</strong>
                    <div className="project-name">Airline Travel App [NDA]</div>
                  </div>
                </Link>
                <Link to='/work/moxxie' className="nav-link next flexbox">
                  <div className="aligner">
                    <strong>Next project</strong>
                    <div className="project-name">Moxxie</div>
                  </div>
                  <ChevronRight className="icon" />
                </Link>
              </div>
            </div>
            </section>
          </main>
          <FooterContent />
        </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("hr-candidate-testing"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HrTest);
