import React, { Component } from "react";
import { Link } from "@reach/router";
import { ChevronRight } from '@material-ui/icons';

import "./WorkSamplesContent.less";

export default class WorkSamplesContent extends Component {
  render() {
    return (
        <section className="main-section default-padding work-samples gray-background">
          <div className="content-wrapper">
                <div className="section-content flexbox space-between top-align">
                  <div className="section-copy">
                    <Link to='/work/moxxie'>
                        <div className="project-card">
                            <img src={require('../../assets/images/tile-cover-moxxie.jpg')} alt="Moxxie" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Track employees’ happiness</span>
                            <strong className="project-name">Moxxie</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>

                    <Link to='/work/seo-site-checkup'>
                        <div className="project-card">
                            <img src={require('../../assets/images/tile-cover-seo-site-checkup.jpg')} alt="SEO Site Checkup" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Improve your website's SEO</span>
                            <strong className="project-name">SEO Site Checkup</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>
                  </div>
                  <div className="section-copy">
                    <h1>Some of our selected works<span className="primary-color">.</span></h1>
                    <Link to='/work' className="main-link">
                      <ChevronRight className="icon" /> See all Work
                    </Link>
                    <div className="spacer-48" />
                    <Link to='/work/ihopa'>
                        <div className="project-card">
                            <img src={require('../../assets/images/tile-cover-ihopa.jpg')} alt="ihopa" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Afford the cool stuff while paying less</span>
                            <strong className="project-name">ihopa</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>

                    <Link to='/work/expenses-time-management'>
                        <div className="project-card">
                            <img src={require('../../assets/images/tile-cover-expense-time-management-app.jpg')} alt="Expenses &amp; Time Management [NDA]" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Total control of time and finances</span>
                            <strong className="project-name">Finances &amp; Time Reporting App</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>
                  </div>
                </div>
          </div>
        </section>
    );
  }
}
