import {
  PAGE_CONTENT_LOADED,
  LOAD_PAGE_CONTENT,
  PAGE_CONTENT_BLOCKS_LOADED,
  LOAD_PAGE_CONTENT_BLOCKS
} from "../actions/content/ContentActions";

const defaultContentState = {
  pages: {},
  loading_pages: {},
  loading_content_blocks: {}
};

const convertContentBlocksToDict = contentBlocksAsList => {
  let contentBlocksAsDict = {};
  contentBlocksAsList.map(contentBlock => {
    contentBlocksAsDict[contentBlock["key"]] = contentBlock;
  });
  return contentBlocksAsDict;
};
const content = (state = defaultContentState, action) => {
  switch (action.type) {
    case PAGE_CONTENT_LOADED:
      return {
        ...state,
        pages: { ...state.pages, [action.pageSlug]: action["content"] },
        loading_pages: { ...state.loading_pages, [action.pageSlug]: false }
      };
    case LOAD_PAGE_CONTENT:
      return {
        ...state,
        loading_pages: { ...state.loading_pages, [action.pageSlug]: true }
      };
    case PAGE_CONTENT_BLOCKS_LOADED:
      const page = state.pages[action.pageSlug];

      return {
        ...state,
        pages: {
          ...state.pages,
          [action.pageSlug]: {
            ...page,
            content_blocks: convertContentBlocksToDict(action.contentBlocks)
          }
        },
        loading_content_blocks: {
          ...state.loading_content_blocks,
          [action.pageSlug]: false
        }
      };
    case LOAD_PAGE_CONTENT_BLOCKS:
      return {
        ...state,
        loading_content_blocks: {
          ...state.loading_content_blocks,
          [action.pageSlug]: true
        }
      };
    default:
      return state;
  }
};
export default content;
