import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import CustomIcon from "../components/CustomIcon";
import "../styles/Services.less";

import FooterContent from "../containers/Footer/FooterContent";
import WorkSamplesContent from "../containers/WorkSamples/WorkSamplesContent";
import TechStack from "../containers/TechStack/TechStack";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class CloudArchitecture extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>Cloud Architecture on AWS Services - Eggnita</title>
            <link rel="canonical" href="https://eggnita.com/services/aws-cloud-architecture" />
            <meta name="description" content="We make sure that every company’s cloud computing strategy is perfectly deployed. This includes using AWS, an Amazon Service that helps us create more sophisticated applications, securing the database and simplifying the implementation." />
            <meta name="keywords" content="cloud, architecture, computing, database, aws, service, eggnita, amazon, secure, application, implementation" />
            <meta name="robots" content="index,follow" />
            
            <meta name="og:title" content="Cloud Architecture on AWS Services - Eggnita" />
            <meta name="og:type" content="article" />
            <meta name="og:url" content="https://eggnita.com/services/aws-cloud-architecture" />
            <meta name="og:image" content="https://eggnita.com/og-image.png" />
            <meta name="og:site_name" content="Eggnita" />
            <meta name="og:description" content="We make sure that every company’s cloud computing strategy is perfectly deployed. This includes using AWS, an Amazon Service that helps us create more sophisticated applications, securing the database and simplifying the implementation." />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@eggnitastudio" />
            <meta name="twitter:title" content="Cloud Architecture on AWS Services - Eggnita" />
            <meta name="twitter:description" content="We make sure that every company’s cloud computing strategy is perfectly deployed. This includes using AWS, an Amazon Service that helps us create more sophisticated applications, securing the database and simplifying the implementation." />  
            <meta name="twitter:creator" content="@eggnitastudio" />
            <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

            <script type="application/ld+json">{`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Services",
                "item": "https://eggnita.com/services"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Cloud Architecture on AWS",
                "item": "https://eggnita.com/services/aws-cloud-architecture"
              }]
            }
            `}</script>

            <script type="application/ld+json">{`
            {
              "@context": "http://schema.org/",
              "@type": "Service",
              "serviceType": "Cloud Architecture on AWS"
            }
            `}</script>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li className="selected">
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li className="selected">
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding service-detail">
            <div className="content-wrapper">
                  <div className="section-content flexbox space-between">
                      <div className="section-copy">
                        <nav className="breadcrumb">
                          <ol>
                            <li>
                              <Link to='/services'><ChevronLeft className="icon" /> Services</Link>
                            </li>
                            <li className="selected">
                              <strong>Cloud Architecture on AWS</strong>
                            </li>
                          </ol>
                        </nav>
                        <h1>Cloud Architecture on AWS<span className="primary-color">.</span></h1>
                        <h3 className="normal-weight">No matter the market or the complexity of the project, our services include all the solutions to develop and to bring to life products that users will love.</h3>
                        <p className="larger-paragraph">We make sure that every company’s cloud computing strategy is perfectly deployed. This includes using AWS, an Amazon Service that helps us create more sophisticated applications, securing the database and simplifying the implementation.</p>
                        <Link to='/contact' className="main-link"><ChevronRight className="icon" /> Get in touch</Link>
                      </div>
                      <div className="section-side">
                        <div className="service-image">
                          <div className="section-icon">
                            <CustomIcon name="cloud-architecture" className="icon" />
                          </div>
                          <img src={require('../assets/images/image-cloud-architecture.png')} alt="Cloud Architecture on AWS" className="media-item" />
                        </div>
                      </div>
                  </div>
            </div>
          </section>
          <TechStack />
          <div className="white-background"><WorkSamplesContent /></div>
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("aws-cloud-architecture"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CloudArchitecture);
