import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import CustomIcon from "../components/CustomIcon";
import "../styles/About.less";

import FooterContent from "../containers/Footer/FooterContent";
import WorkSamplesContent from "../containers/WorkSamples/WorkSamplesContent";
import OurProcess from "../containers/OurProcess/OurProcess";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class About extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>About us — Eggnita</title>
            <link rel="canonical" href="https://eggnita.com/about" />
            <meta name="description" content="We are Eggnita, a digital agency founded in 2011. We're based in Romania, in the beautiful cities of Cluj-Napoca &amp; Iași." />
            <meta name="keywords" content="eggnita, digital, agency, romania, iasi, cluj, it, solutions, vlad mihuta, alex sofronea, aurelian hopulele, lucian toma, elia mihuta, catalin apavaloaie, anda simion, andrei alupei" />
            <meta name="robots" content="index,follow" />
            
            <meta name="og:title" content="About us — Eggnita" />
            <meta name="og:type" content="article" />
            <meta name="og:url" content="https://eggnita.com/about" />
            <meta name="og:image" content="https://eggnita.com/og-image.png" />
            <meta name="og:site_name" content="Eggnita" />
            <meta name="og:description" content="We are Eggnita, a digital agency founded in 2011. We're based in Romania, in the beautiful cities of Cluj-Napoca &amp; Iași." />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@eggnitastudio" />
            <meta name="twitter:title" content="About us — Eggnita" />
            <meta name="twitter:description" content="We are Eggnita, a digital agency founded in 2011. We're based in Romania, in the beautiful cities of Cluj-Napoca &amp; Iași." />  
            <meta name="twitter:creator" content="@eggnitastudio" />
            <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Eggnita",
                "alternateName": "Eggnita Studio SRL",
                "url": "https://eggnita.com",
                "logo": "https://eggnita.com/logo.png",
                "email": "mailto:office@eggnita.com",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Cluj-Napoca",
                  "addressRegion": "Cluj",
                  "postalCode": "400377",
                  "streetAddress": "Salcamului 30"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+40-741-153-949",
                  "contactType": "customer service",
                  "contactOption": "TollFree",
                  "areaServed": ["150","419","US","GB","CA"],
                  "availableLanguage": ["en","Romanian"]
                },
                "sameAs": [
                  "https://www.facebook.com/eggnita",
                  "https://twitter.com/eggnitastudio",
                  "https://www.linkedin.com/company/eggnita",
                  "https://github.com/eggnita"
                ]
              }
            `}</script>

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Vlad Mihuta",
                "url": "https://www.linkedin.com/in/vladmihuta/",
                "image": "https://eggnita.com/image-vlad-mihuta.jpg",
                "sameAs": [
                  "https://www.linkedin.com/in/vladmihuta/",
                  "https://eggnita.com"
                ],
                "jobTitle": "Head of Development",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Eggnita"
                }  
              }
            `}</script>

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Alex Sofronea",
                "url": "https://www.linkedin.com/in/alexsofronea/",
                "image": "https://eggnita.com/image-alex-sofronea.jpg",
                "sameAs": [
                  "https://www.linkedin.com/in/alexsofronea/",
                  "https://eggnita.com"
                ],
                "jobTitle": "UI/UX Designer",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Eggnita"
                }  
              }
            `}</script>

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Aurelian Hopulele",
                "url": "https://www.linkedin.com/in/aurelian-hopulele-gligor-33b741179/",
                "image": "https://eggnita.com/image-aurelian-hopulele.jpg",
                "sameAs": [
                  "https://www.linkedin.com/in/aurelian-hopulele-gligor-33b741179/",
                  "https://eggnita.com"
                ],
                "jobTitle": "Back-end Developer",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Eggnita"
                }  
              }
            `}</script>

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Lucian Toma",
                "image": "https://eggnita.com/image-lucian-toma.jpg",
                "sameAs": [
                  "https://eggnita.com"
                ],
                "jobTitle": "Front-end Developer",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Eggnita"
                }  
              }
            `}</script>

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Elia Mihuta",
                "url": "https://www.linkedin.com/in/elia-mihuta-1b0803118/",
                "image": "https://eggnita.com/image-elia-mihuta.jpg",
                "sameAs": [
                  "https://www.linkedin.com/in/elia-mihuta-1b0803118/",
                  "https://eggnita.com"
                ],
                "jobTitle": "Copywriter",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Eggnita"
                }  
              }
            `}</script>

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Catalin Apavaloaie",
                "url": "https://www.linkedin.com/in/catalin-apavaloaie-5b5a3745/",
                "image": "https://eggnita.com/image-catalin-apavaloaie.jpg",
                "sameAs": [
                  "https://www.linkedin.com/in/catalin-apavaloaie-5b5a3745/",
                  "https://eggnita.com"
                ],
                "jobTitle": "Front-end Developer",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Eggnita"
                }  
              }
            `}</script>

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Anda Simion",
                "image": "https://eggnita.com/image-anda-simion.jpg",
                "sameAs": [
                  "https://eggnita.com"
                ],
                "jobTitle": "Front-end Developer",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Eggnita"
                }  
              }
            `}</script>

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Andrei Alupei",
                "image": "https://eggnita.com/image-andrei-alupei.jpg",
                "sameAs": [
                  "https://eggnita.com"
                ],
                "jobTitle": "Sales & Administrative",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Eggnita"
                }  
              }
            `}</script>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li className="selected">
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li className="selected">
                  <Link to='/about'>About</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding about-page-hero">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between">
                  <div className="section-copy wide-content">
                    <h1>About us<span className="primary-color">.</span></h1>
                    <h3 className="normal-weight">We are Eggnita, a digital agency founded in 2011. We're based in Romania, in the beautiful cities of Cluj-Napoca &amp; Iași.</h3>
                  </div>
              </div>
              <hr />
              <div className="about-key-points">
                <div className="key-item">
                  <div className="key-icon"><CustomIcon name="time" className="icon" /></div>
                  <div className="key-name">Early Beginnings</div>
                  <p className="larger-paragraph">From our very start as teenagers passionate about computers and the digital world, we came up with having a great team of IT developers and designers whose main goal is to create life-improving software.</p>
                </div>
                <div className="key-item">
                  <div className="key-icon"><CustomIcon name="goal" className="icon" /></div>
                  <div className="key-name">The Mission</div>
                  <p className="larger-paragraph">We believe in delivering good software, using the most professional means of IT development. This is why our mission is to provide highly innovative startups, challenging applications and complex digital solutions for our society. </p>
                </div>
                <div className="key-item">
                  <div className="key-icon"><CustomIcon name="adapt" className="icon" /></div>
                  <div className="key-name">Always Adapting</div>
                  <p className="larger-paragraph">On our way to creating software solutions, we adapt to the best practices in our domain, always focusing on the customer satisfaction and using the best technologies, no matter the complexity of the project.</p>
                </div>
                <div className="key-item">
                  <div className="key-icon"><CustomIcon name="values" className="icon" /></div>
                  <div className="key-name">Core Values</div>
                  <p className="larger-paragraph">People are the most important and all we care about is building trust-based relationships and a friendly work environment. Our core value is taking care of working and feeling better together. </p>
                </div>
              </div>
            </div>
          </section>

          <section className="main-section default-padding about-page-team gray-background">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between">
                  <div className="section-copy wide-content">
                    <h1>Our team<span className="primary-color">.</span></h1>
                    <h3 className="normal-weight">We're always looking for new talents to join our team. If you would like to become an Eggniter, please <a href="mailto:office@eggnita.com" target="_blank" rel="noopener noreferrer" className="default-inline-link">drop us an e-mail</a> or head to the <Link to='/careers' className="default-inline-link">careers page</Link> to see what's available now.</h3>
                  </div>
              </div>
              <div className="team-listing">
                <div className="team-member">
                  <div className="image-wrapper">
                    <a href="https://www.linkedin.com/in/vladmihuta/" target="_blank" rel="noopener noreferrer" className="external-url">
                      <CustomIcon name="linked-in" className="icon" />
                    </a>
                    <img src={require('../assets/images/image-vlad-mihuta.jpg')} alt="Vlad Mihuta - Co-founder, Head of development" className="media-item" />
                  </div>
                  <h2>Vlad Mihuță</h2>
                  <div className="member-position">Co-founder, Head of development</div>
                </div>

                <div className="team-member">
                  <div className="image-wrapper">
                    <a href="https://www.linkedin.com/in/alexsofronea/" target="_blank" rel="noopener noreferrer" className="external-url">
                      <CustomIcon name="linked-in" className="icon" />
                    </a>
                    <img src={require('../assets/images/image-alex-sofronea.jpg')} alt="Alex Sofronea - Co-founder, UI/UX Designer" className="media-item" />
                  </div>
                  <h2>Alex Sofronea</h2>
                  <div className="member-position">Co-founder, UI/UX Designer</div>
                </div>

                <div className="team-member">
                  <div className="image-wrapper">
                    <a href="https://www.linkedin.com/in/aurelian-hopulele-gligor-33b741179/" target="_blank" rel="noopener noreferrer" className="external-url">
                      <CustomIcon name="linked-in" className="icon" />
                    </a>
                    <img src={require('../assets/images/image-aurelian-hopulele.jpg')} alt="Aurelian Hopulele - Back-end Developer" className="media-item" />
                  </div>
                  <h2>Aurelian Hopulele</h2>
                  <div className="member-position">Back-end Developer</div>
                </div>

                <div className="team-member">
                  <img src={require('../assets/images/image-lucian-toma.jpg')} alt="Lucian Toma - Front-end Developer" className="media-item" />
                  <h2>Lucian Toma</h2>
                  <div className="member-position">Front-end Developer</div>
                </div>

                <div className="team-member">
                  <div className="image-wrapper">
                    <a href="https://www.linkedin.com/in/elia-mihuta-1b0803118/" target="_blank" rel="noopener noreferrer" className="external-url">
                      <CustomIcon name="linked-in" className="icon" />
                    </a>
                    <img src={require('../assets/images/image-elia-mihuta.jpg')} alt="Elia Mihuță - Copywriter" className="media-item" />
                  </div>
                  <h2>Elia Mihuță</h2>
                  <div className="member-position">Copywriter</div>
                </div>

                <div className="team-member">
                  <div className="image-wrapper">
                    <a href="https://www.linkedin.com/in/catalin-apavaloaie-5b5a3745/" target="_blank" rel="noopener noreferrer" className="external-url">
                      <CustomIcon name="linked-in" className="icon" />
                    </a>
                    <img src={require('../assets/images/image-catalin-apavaloaie.jpg')} alt="Cătălin Apăvăloaie - Front-end Developer" className="media-item" />
                  </div>
                  <h2>Cătălin Apăvăloaie</h2>
                  <div className="member-position">Front-end Developer</div>
                </div>

                <div className="team-member">
                  <img src={require('../assets/images/image-anda-simion.jpg')} alt="Anda Simion - Front Developer" className="media-item" />
                  <h2>Anda Simion</h2>
                  <div className="member-position">Front-end Developer</div>
                </div>

                <div className="team-member">
                  <img src={require('../assets/images/image-andrei-alupei.jpg')} alt="Andrei Alupei - Sales &amp; Administrative" className="media-item" />
                  <h2>Andrei Alupei</h2>
                  <div className="member-position">Sales &amp; Administrative</div>
                </div>
              </div>
            </div>
          </section>
        </main>
        
        <div className="white-background"><OurProcess /></div>
        <WorkSamplesContent />
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("about"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About);
