import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import "../styles/Work.less";

import FooterContent from "../containers/Footer/FooterContent";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class Moxxie extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>Moxxie - Eggnita</title>
            <link rel="canonical" href="https://eggnita.com/work/moxxie" />
            <meta name="description" content="Track your employees’ happiness in just seconds and ensure continuous workplace improvement through actionable pulse surveys." />
            <meta name="keywords" content="moxxie, employee, happiness, workplace, improvement, pulse, survey, eggnita, ui, ux, design, logo, front end, back end, development, cloud, architecture, aws, amazon" />
            <meta name="robots" content="index,follow" />
            
            <meta name="og:title" content="Moxxie - Eggnita" />
            <meta name="og:type" content="article" />
            <meta name="og:url" content="https://eggnita.com/work/moxxie" />
            <meta name="og:image" content="https://eggnita.com/og-image.png" />
            <meta name="og:site_name" content="Eggnita" />
            <meta name="og:description" content="Track your employees’ happiness in just seconds and ensure continuous workplace improvement through actionable pulse surveys." />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@eggnitastudio" />
            <meta name="twitter:title" content="Moxxie - Eggnita" />
            <meta name="twitter:description" content="Track your employees’ happiness in just seconds and ensure continuous workplace improvement through actionable pulse surveys." />  
            <meta name="twitter:creator" content="@eggnitastudio" />
            <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

            <script type="application/ld+json">{`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Work",
                "item": "https://eggnita.com/work"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Moxxie",
                "item": "https://eggnita.com/work/moxxie"
              }]
            }
            `}</script>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li className="selected">
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li className="selected">
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding project-page-details">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between top-align">
                  <div className="section-copy wide-content">
                    <nav className="breadcrumb">
                      <ol>
                        <li>
                          <Link to='/work'><ChevronLeft className="icon" /> Work</Link>
                        </li>
                        <li className="selected">
                          <strong>Moxxie</strong>
                        </li>
                      </ol>
                    </nav>
                    <h1>Moxxie<span className="primary-color">.</span></h1>
                    <h3 className="normal-weight">Track your employees’ happiness in just seconds and ensure continuous workplace improvement through actionable pulse surveys.</h3>
                    <a href="https://moxxie.app/" className="main-link" target="_blank" rel="noopener noreferrer"><ChevronRight className="icon" /> Check it Live</a>
                  </div>
                  <div className="section-side project-info">
                    <div className="info-item">
                      <strong>Client</strong>
                      <span>Moxxie</span>
                    </div>
                    <div className="info-item">
                      <strong>Year</strong>
                      <span>2019</span>
                    </div>
                    <div className="info-item">
                      <strong>Services</strong>
                      <span>UI/UX Design, Logo Design, Front-end Development, Back-end Development, Cloud Architecture on AWS</span>
                    </div>
                  </div>
              </div>
              <div className="spacer-64" />
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">01</span> Visual Identity</div>
                <img src={require('../assets/images/project-image-moxxie-03.jpg')} alt="Moxxie - Visual Identity" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">02</span> Colors &amp; Typography</div>
                <img src={require('../assets/images/project-image-moxxie-05.jpg')} alt="Moxxie - Colors &amp; Typography" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">03</span> Interface Components</div>
                <img src={require('../assets/images/project-image-moxxie-04.jpg')} alt="Moxxie - Interface Components" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">04</span> Employee Flow</div>
                <img src={require('../assets/images/project-image-moxxie-02.jpg')} alt="Moxxie - Employee Flow" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">05</span> Manager's Dashboard</div>
                <img src={require('../assets/images/project-image-moxxie-01.jpg')} alt="Moxxie - Manager Dashboard" className="media-item" />
              </div>
              <div className="project-image">
                <div className="image-caption"><span className="caption-number">06</span> Manager's Staff</div>
                <img src={require('../assets/images/project-image-moxxie-06.jpg')} alt="Moxxie - Manager Staff" className="media-item" />
              </div>
              <div className="project-navigation flexbox space-between">
                <Link to='/work/hr-candidate-testing' className="nav-link previous flexbox">
                  <ChevronLeft className="icon" />
                  <div className="aligner">
                    <strong>Previous project</strong>
                    <div className="project-name">HR Candidate Testing [NDA]</div>
                  </div>
                </Link>
                <Link to='/work/ihopa' className="nav-link next flexbox">
                  <div className="aligner">
                    <strong>Next project</strong>
                    <div className="project-name">ihopa</div>
                  </div>
                  <ChevronRight className="icon" />
                </Link>
              </div>
            </div>
          </section>
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("moxxie"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Moxxie);
