import React, { Component } from "react";
import { Link } from "@reach/router";
import { ChevronRight } from '@material-ui/icons';

import "./WhoWeAreContent.less";

export default class WhoWeAreContent extends Component {
  render() {
    return (
        <section className="main-section default-padding who-we-are" id="after-hero">
          <div className="content-wrapper">
                <div className="section-content flexbox space-between top-align">
                    <div className="section-copy">
                      <h1>Who we are<span className="primary-color">.</span></h1>
                      <h3 className="normal-weight">We're a digital agency based in Romania, always striving to create great products that are a joy to use.</h3>
                      <p className="larger-paragraph">Founded in 2011, enthusiastic when it comes to developing IT solutions, creative when designing digital products, <strong>Eggnita</strong> is a medium-sized digital agency, comfortably nested in <strong>Cluj-Napoca &amp; Iași, Romania</strong>, with big challenges waiting to come. </p>
                      <p className="larger-paragraph">We put data and design in the spotlight to create professional web and mobile apps, always focusing on usability and user experience.</p>
                    </div>
                    <div className="section-side">
                      <div className="team-image">
                        <img src={require('../../assets/images/image-who-we-are.jpg')} alt="Who we are" className="media-item" />
                      </div>
                      <p className="larger-paragraph">We believe in delivering good software, using the most professional means of IT development. This is why our mission is to provide highly innovative startups, challenging applications and complex digital solutions for our society.</p>
                    </div>
                </div>
                <Link to='/about' className="main-link"><ChevronRight className="icon" /> More About Us</Link>
          </div>
        </section>
    );
  }
}
