import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { ChevronRight } from '@material-ui/icons';
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";

import FooterContent from "../containers/Footer/FooterContent";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class NotFound extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>404 Page not found — Eggnita</title>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding about-page-hero">
            <div className="content-wrapper">
                <h1>Auch, this page doesn't exist<span className="primary-color">.</span></h1>
                <h3 className="normal-weight">Well done! You managed to find the 404 page.</h3>
                <Link to='/' className="main-link">
                    <ChevronRight className="icon" /> Go to main page
                </Link>
            </div>
          </section>
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("404"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFound);
