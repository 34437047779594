import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import Headroom from "react-headroom";
import { Helmet } from "react-helmet";
import "../styles/Contact.less";

import FooterContent from "../containers/Footer/FooterContent";

import { slide as Menu } from "react-burger-menu";
import { MenuRounded, CloseRounded } from "@material-ui/icons";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
          </script>
          <title>Contact — Eggnita</title>
          <link rel="canonical" href="https://eggnita.com/contact" />
          <meta name="description" content="For project inquiries or just for shouting a friendly Salut at us, you can always contact us on our email." />
          <meta name="keywords" content="contact, eggnita, project, email, cluj, cluj napoca, iasi, romania" />
          <meta name="robots" content="index,follow" />

          <meta name="og:title" content="Contact — Eggnita" />
          <meta name="og:type" content="article" />
          <meta name="og:url" content="https://eggnita.com/contact" />
          <meta name="og:image" content="https://eggnita.com/og-image.png" />
          <meta name="og:site_name" content="Eggnita" />
          <meta name="og:description" content="For project inquiries or just for shouting a friendly Salut at us, you can always contact us on our email." />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@eggnitastudio" />
          <meta name="twitter:title" content="Contact — Eggnita" />
          <meta name="twitter:description" content="For project inquiries or just for shouting a friendly Salut at us, you can always contact us on our email." />
          <meta name="twitter:creator" content="@eggnitastudio" />
          <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

          <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Eggnita",
                "alternateName": "Eggnita Studio SRL",
                "url": "https://eggnita.com",
                "logo": "https://eggnita.com/logo.png",
                "email": "mailto:office@eggnita.com",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Cluj-Napoca",
                  "addressRegion": "Cluj",
                  "postalCode": "400377",
                  "streetAddress": "Salcamului 30"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+40-741-153-949",
                  "contactType": "customer service",
                  "contactOption": "TollFree",
                  "areaServed": ["150","419","US","GB","CA"],
                  "availableLanguage": ["en","Romanian"]
                },
                "sameAs": [
                  "https://www.facebook.com/eggnita",
                  "https://twitter.com/eggnitastudio",
                  "https://www.linkedin.com/company/eggnita",
                  "https://github.com/eggnita"
                ]
              }
            `}</script>
        </Helmet>
        <Menu
          disableAutoFocus
          right
          width={"65%"}
          customBurgerIcon={<MenuRounded className="icon" />}
          customCrossIcon={<CloseRounded className="icon" />}
          bodyClassName={"no-scroll"}
          isOpen={this.state.menuOpen}
          onStateChange={state => this.handleStateChange(state)}
          className="mobile-nav-drawer">
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/work">Work</Link>
            </li>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li className="selected">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to="/">
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/work">Work</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="selected">
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
              <div className="mobile-navigation">
                <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                  <MenuRounded className="icon" />
                  <strong>Menu</strong>
                </div>
              </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding contact-page-hero">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between">
                <div className="section-copy wide-content">
                  <h1>
                    Contact us<span className="primary-color">.</span>
                  </h1>
                  <h3 className="normal-weight">
                    For project inquiries or just for shouting a friendly "Salut" at us, you can always contact our team on{" "}
                    <a
                      href="mailto:office@eggnita.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="default-inline-link">
                      office@eggnita.com
                    </a>
                  </h3>
                </div>
              </div>
              <div className="spacer-48" />
              <div className="section-content flexbox space-between top-align">
                <div className="section-copy">
                  <img
                    src={require("../assets/images/image-cluj-napoca.jpg")}
                    alt="Cluj-Napoca, Romania"
                    className="media-item"
                  />
                  <div className="contact-location">Meet us in Cluj-Napoca</div>
                  <p>
                    str. Salcâmului, nr. 30
                    <br />
                    Cluj-Napoca, Romania
                  </p>
                </div>
                <div className="section-copy">
                  <img src={require("../assets/images/image-iasi.jpg")} alt="Iasi, Romania" className="media-item" />
                  <div className="contact-location">Meet us in Iași</div>
                  <p>
                    str. Zimbrului nr. 3<br />
                    Iași, Romania
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("contact"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
