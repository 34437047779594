// import { createSelector } from "reselect";

const getPage = (state, props) => {
  return state.content.pages[props.pageSlug] || {};
};

const isPageLoading = (state, props) => {
  if (state.content.loading_pages[props.pageSlug] === true) {
    return true;
  } else {
    return false;
  }
};
const shouldFetchPage = (state, props) => {
  //TODO: here we can add an expiration date to the content so we can refresh it if needed
  if (isPageLoading(state, props)) {
    return false;
  }
  if (getPage(state, props)["id"]) {
    return false;
  }
  return true;
};

export { isPageLoading, getPage, shouldFetchPage };
