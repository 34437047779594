import React, { Component } from "react";
import CustomIcon from "../../components/CustomIcon";

import "./TechStack.less";

export default class TechStack extends Component {
  render() {
    return (
        <section className="main-section default-padding tech-stack-section gray-background">
          <div className="content-wrapper">
            <h1 className="">Our main tech stack<span className="primary-color">.</span></h1>
            <h3 className=" normal-weight">Here is a list of technologies we use during product development.</h3>
            <div className="tech-stack-cloud">
                <div className="tech-item react-js">
                    <div className="tech-icon"><CustomIcon name="react" className="icon" /></div>
                    <div className="tech-label"><strong>React JS</strong></div>
                </div>
                <div className="tech-item react-native">
                    <div className="tech-icon"><CustomIcon name="react" className="icon" /></div>
                    <div className="tech-label"><strong>React Native</strong></div>
                </div>
                <div className="tech-item html5">
                    <div className="tech-icon"><CustomIcon name="html" className="icon" /></div>
                    <div className="tech-label"><strong>HTML5</strong></div>
                </div>
                <div className="tech-item css3">
                    <div className="tech-icon"><CustomIcon name="css" className="icon" /></div>
                    <div className="tech-label"><strong>CSS3</strong></div>
                </div>
                <div className="tech-item lit">
                    <div className="tech-icon"><CustomIcon name="lit" className="icon" /></div>
                    <div className="tech-label"><strong>Polymer &amp; Lit Element</strong></div>
                </div>
                <div className="tech-item web-components">
                    <div className="tech-icon"><CustomIcon name="web-components" className="icon" /></div>
                    <div className="tech-label"><strong>Web Components</strong></div>
                </div>
                <div className="tech-item js">
                    <div className="tech-icon"><CustomIcon name="js" className="icon" /></div>
                    <div className="tech-label"><strong>Vanilla JS</strong></div>
                </div>
                <div className="tech-item es6">
                    <div className="tech-icon"><CustomIcon name="es6" className="icon" /></div>
                    <div className="tech-label"><strong>ECMAScript 6</strong></div>
                </div>
                <div className="tech-item node-js">
                    <div className="tech-icon"><CustomIcon name="node" className="icon" /></div>
                    <div className="tech-label"><strong>Node JS</strong></div>
                </div>
                <div className="tech-item redux">
                    <div className="tech-icon"><CustomIcon name="redux" className="icon" /></div>
                    <div className="tech-label"><strong>Redux</strong></div>
                </div>
                <div className="tech-item aws">
                    <div className="tech-icon"><CustomIcon name="aws" className="icon" /></div>
                    <div className="tech-label"><strong>AWS Certified Cloud Practitioner</strong></div>
                </div>
                <div className="tech-item python">
                    <div className="tech-icon"><CustomIcon name="python" className="icon" /></div>
                    <div className="tech-label"><strong>Python</strong></div>
                </div>
            </div>
          </div>
        </section>
    );
  }
}
