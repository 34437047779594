import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";

import FooterContent from "../containers/Footer/FooterContent";
import MainHeroContent from "../containers/MainHero/MainHeroContent";
import WhoWeAreContent from "../containers/WhoWeAre/WhoWeAreContent";
import WhatWeDoContent from "../containers/WhatWeDo/WhatWeDoContent";
import WorkSamplesContent from "../containers/WorkSamples/WorkSamplesContent";
// import IndustryHero from "../containers/IndustryHero/IndustryHeroContent";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';

class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
      <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-149439507-1');
            `}
          </script>
          <title>Eggnita - Digital agency based in Cluj-Napoca &amp; Iași, Romania</title>
          <link rel="canonical" href="https://eggnita.com/" />
          <meta name="description" content="We put data and design in the spotlight to create professional web and mobile apps, always focusing on usability and user experience." />
          <meta name="keywords" content="eggnita, digital, agency, cluj, cluj napoca, iasi, romania, data, design, web, development, dev, professional, mobile, app, application, usability, ui, ux, user experience, web services, back end, cloud, architecture, aws, amazon, social media, copywrite, seo, optimization, search engine optimization, startup, technical, technology, product, management, consultant, consultancy, retail, travel, leisure, fintech, recruitment, hr, saas, software as a service, ngo, social services" />
          <meta name="robots" content="index,follow" />
          
          <meta name="og:title" content="Eggnita - Digital agency based in Cluj-Napoca &amp; Iași, Romania" />
          <meta name="og:type" content="website" />
          <meta name="og:url" content="https://www.eggnita.com/" />
          <meta name="og:image" content="https://eggnita.com/og-image.png" />
          <meta name="og:site_name" content="Eggnita" />
          <meta name="og:description" content="We put data and design in the spotlight to create professional web and mobile apps, always focusing on usability and user experience." />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@eggnitastudio" />
          <meta name="twitter:title" content="Eggnita - Digital agency based in Cluj-Napoca &amp; Iași, Romania" />
          <meta name="twitter:description" content="We put data and design in the spotlight to create professional web and mobile apps, always focusing on usability and user experience." />  
          <meta name="twitter:creator" content="@eggnitastudio" />
          <meta name="twitter:image" content="https://eggnita.com/og-image.png" />
          <meta name="classification" content="04340"/>

          <script type="application/ld+json">{`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Eggnita",
              "alternateName": "Eggnita Studio SRL",
              "url": "https://eggnita.com",
              "logo": "https://eggnita.com/logo.png",
              "email": "mailto:office@eggnita.com",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Cluj-Napoca",
                "addressRegion": "Cluj",
                "postalCode": "400377",
                "streetAddress": "Salcamului 30"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+40-741-153-949",
                "contactType": "customer service",
                "contactOption": "TollFree",
                "areaServed": ["150","419","US","GB","CA"],
                "availableLanguage": ["en","Romanian"]
              },
              "sameAs": [
                "https://www.facebook.com/eggnita",
                "https://twitter.com/eggnitastudio",
                "https://www.linkedin.com/company/eggnita",
                "https://github.com/eggnita"
              ]
            }
          `}</script>

          <script type="application/ld+json">{`
          {
            "@context": "http://schema.org/",
            "@type": "Service",
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Web and Design Services",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Web Applications"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Mobile Applications"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Web Services"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Back-end Solutions"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "UI/UX Design"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Cloud Architecture on AWS"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Social Media"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Copywriting"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "SEO Services"
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Startup Consultancy"
                  }
                }
              ]
          }
          }
          `}</script>
      </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom className="has-big-hero">
          <header className="app-header transparent flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
              <div className="mobile-navigation">
                <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                  <MenuRounded className="icon" /> 
                  <strong>Menu</strong>
                </div>
              </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content has-big-hero">
          <MainHeroContent />
        <div>
      </div>
          <WhoWeAreContent />
          <WorkSamplesContent />
          <WhatWeDoContent />
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("home"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
