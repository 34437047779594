import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import { ChevronRight } from '@material-ui/icons';
import "../styles/Work.less";

import FooterContent from "../containers/Footer/FooterContent";
import WhatWeDoContent from "../containers/WhatWeDo/WhatWeDoContent";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class Work extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>Work — Eggnita</title>
            <link rel="canonical" href="https://eggnita.com/work" />
            <meta name="description" content="We put data and design in the spotlight to create professional web and mobile apps, always focusing on usability." />
            <meta name="keywords" content="work, portfolio, projects, eggnita, data, design, ui, ux, front end, back end, development, professional, web, mobile, apps, application, usability, moxxie, ihopa, seo site checkup, finances, time reporting, uefa, foundation, children, gjensidige, baby simulator, airline, travel, passenger, hr, candidate, testing, hire" />
            <meta name="robots" content="index,follow" />
            
            <meta name="og:title" content="Work — Eggnita" />
            <meta name="og:type" content="article" />
            <meta name="og:url" content="https://eggnita.com/work" />
            <meta name="og:image" content="https://eggnita.com/og-image.png" />
            <meta name="og:site_name" content="Eggnita" />
            <meta name="og:description" content="We put data and design in the spotlight to create professional web and mobile apps, always focusing on usability." />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@eggnitastudio" />
            <meta name="twitter:title" content="Work — Eggnita" />
            <meta name="twitter:description" content="We put data and design in the spotlight to create professional web and mobile apps, always focusing on usability." />  
            <meta name="twitter:creator" content="@eggnitastudio" />
            <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Eggnita",
                "alternateName": "Eggnita Studio SRL",
                "url": "https://eggnita.com",
                "logo": "https://eggnita.com/logo.png",
                "email": "mailto:office@eggnita.com",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Cluj-Napoca",
                  "addressRegion": "Cluj",
                  "postalCode": "400377",
                  "streetAddress": "Salcamului 30"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+40-741-153-949",
                  "contactType": "customer service",
                  "contactOption": "TollFree",
                  "areaServed": ["150","419","US","GB","CA"],
                  "availableLanguage": ["en","Romanian"]
                },
                "sameAs": [
                  "https://www.facebook.com/eggnita",
                  "https://twitter.com/eggnitastudio",
                  "https://www.linkedin.com/company/eggnita",
                  "https://github.com/eggnita"
                ]
              }
            `}</script>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li className="selected">
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li className="selected">
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding work-page-hero">
            <div className="content-wrapper">
              <div className="section-content flexbox space-between">
                  <div className="section-copy wide-content">
                    <h1>Our Work<span className="primary-color">.</span></h1>
                    <h3 className="normal-weight">We put data and design in the spotlight to create professional web and mobile apps, always focusing on usability.</h3>
                  </div>
              </div>

              <div className="section-content flexbox space-between projects-showcase top-align">
                  <div className="section-copy">
                    <Link to='/work/moxxie'>
                        <div className="project-card">
                            <img src={require('../assets/images/tile-cover-moxxie.jpg')} alt="Moxxie" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Track employees’ happiness</span>
                            <strong className="project-name">Moxxie</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>

                    <Link to='/work/seo-site-checkup'>
                        <div className="project-card">
                            <img src={require('../assets/images/tile-cover-seo-site-checkup.jpg')} alt="SEO Site Checkup" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Improve your website's SEO</span>
                            <strong className="project-name">SEO Site Checkup</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>

                    <Link to='/work/uefa-foundation'>
                        <div className="project-card">
                            <img src={require('../assets/images/tile-cover-uefa-foundation.jpg')} alt="UEFA Foundation for Children" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Believe in the potential of every child</span>
                            <strong className="project-name">UEFA Foundation for Children</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>

                    <Link to='/work/airline-travel-app'>
                        <div className="project-card">
                            <img src={require('../assets/images/tile-cover-airline-travel-app.jpg')} alt="Airline Travel App [NDA]" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Improve passengers' travel experience</span>
                            <strong className="project-name">Airline Travel App [NDA]</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>
                  </div>

                  <div className="section-copy">
                    <Link to='/work/ihopa'>
                        <div className="project-card">
                            <img src={require('../assets/images/tile-cover-ihopa.jpg')} alt="ihopa" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Afford the cool stuff while paying less</span>
                            <strong className="project-name">ihopa</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>

                    <Link to='/work/expenses-time-management'>
                        <div className="project-card">
                            <img src={require('../assets/images/tile-cover-expense-time-management-app.jpg')} alt="Expenses &amp; Time Management [NDA]" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Total control of time and finances</span>
                            <strong className="project-name">Finances &amp; Time Reporting App</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>

                    <Link to='/work/gjensidige-baby-simulator'>
                        <div className="project-card">
                            <img src={require('../assets/images/tile-cover-gjensidige-baby-simulator.jpg')} alt="Gjensidige - Baby Simulator" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Simulate 24 hours with a baby</span>
                            <strong className="project-name">Gjensidige - Baby Simulator</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>

                    <Link to='/work/hr-candidate-testing'>
                        <div className="project-card">
                            <img src={require('../assets/images/tile-cover-hr-candidate-testing.jpg')} alt="HR Candidate Testing [NDA]" className="project-image" />
                          <div className="project-details">
                            <span className="project-tagline">Hire only the best candidates, hassle free</span>
                            <strong className="project-name">HR Candidate Testing [NDA]</strong>
                          </div>
                          <div className="button-link has-icon">
                            <ChevronRight className="icon" /> 
                          </div>
                        </div>
                    </Link>
                  </div>
              </div>
            </div>
          </section>
        </main>
        <div className="gray-background"><WhatWeDoContent /></div>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("work"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Work);
