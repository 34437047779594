import React, { Component } from "react";
import { Link } from "@reach/router";
import CustomIcon from "../../components/CustomIcon";
import { ChevronRight, } from '@material-ui/icons';

import "./WhatWeDoContent.less";

export default class WhatWeDoContent extends Component {
  render() {
    return (
        <section className="main-section default-padding what-we-do">
          <div className="content-wrapper">
                <div className="section-content flexbox space-between top-align">
                    <div className="section-copy">
                        <h1>What we do<span className="primary-color">.</span></h1>
                        <h3 className="normal-weight">Dozens of startups and emerging companies grew their business continuously with the help of our services.</h3>
                        <p className="larger-paragraph">From our very start as teenagers passionate about computers and digital world, we came up with having a great team of IT developers and designers whom main goal is to create life improving software.</p>
                    </div>
                    <div className="section-side">
                      <ul className="default-list services-grid">
                        <li>
                          <Link to='/services/web-applications' className="list-link">
                            <CustomIcon name="web-apps" className="icon" />
                            <span className="list-label">Web Apps</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/mobile-applications' className="list-link">
                            <CustomIcon name="mobile-apps" className="icon" />
                            <span className="list-label">Mobile Apps</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/web-services' className="list-link">
                            <CustomIcon name="web-services" className="icon" />
                            <span className="list-label">Web Services</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/back-end-solutions' className="list-link">
                            <CustomIcon name="back-end" className="icon" />
                            <span className="list-label">Back-end Solutions</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/ui-ux-design' className="list-link">
                            <CustomIcon name="ui-ux" className="icon" />
                            <span className="list-label">UI/UX Design</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/aws-cloud-architecture' className="list-link">
                            <CustomIcon name="cloud-architecture" className="icon" />
                            <span className="list-label">AWS Architecture</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/social-media' className="list-link">
                            <CustomIcon name="social-media" className="icon" />
                            <span className="list-label">Social Media</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/copywriting' className="list-link">
                            <CustomIcon name="copywriting" className="icon" />
                            <span className="list-label">Copywriting</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/seo-services' className="list-link">
                            <CustomIcon name="seo" className="icon" />
                            <span className="list-label">SEO Services</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                        <li>
                          <Link to='/services/startup-consultancy' className="list-link">
                            <CustomIcon name="consultancy" className="icon" />
                            <span className="list-label">Startup Consultancy</span>
                            <ChevronRight className="more-icon" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                </div>
                <Link to='/services' className="main-link">
                  <ChevronRight className="icon" /> Our Services
                </Link>
          </div>
        </section>
    );
  }
}
