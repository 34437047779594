import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchContentForPage } from "../actions/content/ContentActions";
import { Link } from "@reach/router";
import  Headroom from "react-headroom";
import {Helmet} from "react-helmet";
import CustomIcon from "../components/CustomIcon";
import "../styles/Services.less";

import FooterContent from "../containers/Footer/FooterContent";
import WorkSamplesContent from "../containers/WorkSamples/WorkSamplesContent";
import OurProcess from "../containers/OurProcess/OurProcess";
import TechStack from "../containers/TechStack/TechStack";

import { slide as Menu } from 'react-burger-menu'
import { MenuRounded, CloseRounded } from '@material-ui/icons';
class Services extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  render() {
    return (
      <section className="app-wrapper">
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149439507-1"></script>
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-149439507-1');
              `}
            </script>
            <title>Services - Eggnita</title>
            <link rel="canonical" href="https://eggnita.com/services" />
            <meta name="description" content="From initial idea to market-ready product, we’ll guide you through the process and bring your vision to life." />
            <meta name="keywords" content="service, eggnita, web, mobile, apps, application, back end, front end, development, ui, ux, design, aws, amazon, cloud, architecture, database, social, media, copywriting, seo, startup, consultancy" />
            <meta name="robots" content="index,follow" />
            
            <meta name="og:title" content="Services - Eggnita" />
            <meta name="og:type" content="article" />
            <meta name="og:url" content="https://eggnita.com/services" />
            <meta name="og:image" content="https://eggnita.com/og-image.png" />
            <meta name="og:site_name" content="Eggnita" />
            <meta name="og:description" content="From initial idea to market-ready product, we’ll guide you through the process and bring your vision to life." />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@eggnitastudio" />
            <meta name="twitter:title" content="Services - Eggnita" />
            <meta name="twitter:description" content="From initial idea to market-ready product, we’ll guide you through the process and bring your vision to life." />  
            <meta name="twitter:creator" content="@eggnitastudio" />
            <meta name="twitter:image" content="https://eggnita.com/og-image.png" />

            <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Eggnita",
                "alternateName": "Eggnita Studio SRL",
                "url": "https://eggnita.com",
                "logo": "https://eggnita.com/logo.png",
                "email": "mailto:office@eggnita.com",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Cluj-Napoca",
                  "addressRegion": "Cluj",
                  "postalCode": "400377",
                  "streetAddress": "Salcamului 30"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+40-741-153-949",
                  "contactType": "customer service",
                  "contactOption": "TollFree",
                  "areaServed": ["150","419","US","GB","CA"],
                  "availableLanguage": ["en","Romanian"]
                },
                "sameAs": [
                  "https://www.facebook.com/eggnita",
                  "https://twitter.com/eggnitastudio",
                  "https://www.linkedin.com/company/eggnita",
                  "https://github.com/eggnita"
                ]
              }
            `}</script>

            <script type="application/ld+json">{`
            {
              "@context": "http://schema.org/",
              "@type": "Service",
              "hasOfferCatalog": {
                "@type": "OfferCatalog",
                "name": "Web and Design Services",
                "itemListElement": [
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Web Applications"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Mobile Applications"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Web Services"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Back-end Solutions"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "UI/UX Design"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Cloud Architecture on AWS"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Social Media"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Copywriting"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "SEO Services"
                    }
                  },
                  {
                    "@type": "Offer",
                    "itemOffered": {
                      "@type": "Service",
                      "name": "Startup Consultancy"
                    }
                  }
                ]
            }
            }
            `}</script>
        </Helmet>
        <Menu 
          disableAutoFocus 
          right 
          width={ '65%' } 
          customBurgerIcon={ <MenuRounded className="icon" /> } 
          customCrossIcon={ <CloseRounded className="icon" /> } 
          bodyClassName={ "no-scroll" } 
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          className="mobile-nav-drawer"
        >
          <div className="header">
            <span>Menu</span>
          </div>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li className="selected">
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/work'>Work</Link>
            </li>
            <li>
              <Link to='/careers'>Careers</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </Menu>
        <Headroom>
          <header className="app-header flexbox space-between">
            <Link to='/'>
              <div className="logo" />
            </Link>
            <nav>
              <ul className="desktop-navigation">
                <li>
                  <Link to='/about'>About</Link>
                </li>
                <li className="selected">
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/work'>Work</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
              </ul>
                <div className="mobile-navigation">
                  <div className="navigation-trigger flexbox" onClick={() => this.toggleMenu()}>
                    <MenuRounded className="icon" /> 
                    <strong>Menu</strong>
                  </div>
                </div>
            </nav>
          </header>
        </Headroom>
        <main className="app-content">
          <section className="main-section default-padding services-page-hero">
            <div className="content-wrapper">
                  <div className="section-content flexbox space-between">
                      <div className="section-copy wide-content">
                        <h1>Our Services<span className="primary-color">.</span></h1>
                        <h3 className="normal-weight">From initial idea to market-ready product, we’ll guide you through the process and bring your vision to life.</h3>
                      </div>
                  </div>
                  <div className="service-list">
                    <div className="service-item">
                      <Link to='/services/web-applications' className="list-link">
                        <div className="service-icon"><CustomIcon name="web-apps" className="icon" /></div>
                        <div className="service-name"><h2>Web<br/> Applications</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/mobile-applications' className="list-link">
                        <div className="service-icon"><CustomIcon name="mobile-apps" className="icon" /></div>
                        <div className="service-name"><h2>Mobile<br/>  Applications</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/web-services' className="list-link">
                        <div className="service-icon"><CustomIcon name="web-services" className="icon" /></div>
                        <div className="service-name"><h2>Web<br/>  Services</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/back-end-solutions' className="list-link">
                        <div className="service-icon"><CustomIcon name="back-end" className="icon" /></div>
                        <div className="service-name"><h2>Back-end<br/>  Solutions</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/ui-ux-design' className="list-link">
                        <div className="service-icon"><CustomIcon name="ui-ux" className="icon" /></div>
                        <div className="service-name"><h2>UI/UX<br/>  Design</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/aws-cloud-architecture' className="list-link">
                        <div className="service-icon"><CustomIcon name="cloud-architecture" className="icon" /></div>
                        <div className="service-name"><h2>AWS<br/>  Architecture</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/social-media' className="list-link">
                        <div className="service-icon"><CustomIcon name="social-media" className="icon" /></div>
                        <div className="service-name"><h2>Social<br/> media</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/copywriting' className="list-link">
                        <div className="service-icon"><CustomIcon name="copywriting" className="icon" /></div>
                        <div className="service-name"><h2>Copywriting<br/> Services</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/seo-services' className="list-link">
                        <div className="service-icon"><CustomIcon name="seo" className="icon" /></div>
                        <div className="service-name"><h2>SEO<br/>  Services</h2></div>
                      </Link>
                    </div>
                    <div className="service-item">
                      <Link to='/services/startup-consultancy' className="list-link">
                        <div className="service-icon"><CustomIcon name="consultancy" className="icon" /></div>
                        <div className="service-name"><h2>Startup<br/>  Consultancy</h2></div>
                      </Link>
                    </div>
                  </div>
            </div>
          </section>
          <OurProcess />
          <div className="white-background"><TechStack /></div>
          <WorkSamplesContent />
        </main>
        <FooterContent />
      </section>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchContentForPage("services"))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Services);
